<template>
    <div class="PopupPatient" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># 확진정보</h3>
                    </div>
                    <table class="table table-profile" v-if="userDataAdminType == 'MOBILE'">
                        <colgroup>
                            <col width="30%">
                            <col width="70%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">연번</th>
                            <td class="text-left">{{ info.f_num }}</td>
                        </tr>
                        <tr>
                            <th class="field">접수연번</th>
                            <td class="text-left">{{ info.f_date_num }}</td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">{{ info.f_patient_name }} ({{ info.f_patient_age }}세)</td>
                        </tr>
                        <tr>
                            <th class="field">생년월일</th>
                            <td class="text-left">{{ (info.f_patient_birth != '0000-00-00 00:00:00') ? $moment(info.f_patient_birth).format('YYYY-MM-DD') : '' }}</td>
                        </tr>
                        <tr>
                            <th class="field">성별</th>
                            <td class="text-left">{{ info.f_patient_sex }}</td>
                        </tr>
                        <tr>
                            <th class="field">지역</th>
                            <td class="text-left">{{ info.f_region }}</td>
                        </tr>
                        <tr>
                            <th class="field">배정예정</th>
                            <td class="text-left">{{ info.f_assign_type }}</td>
                        </tr>
                        <tr>
                            <th class="field">시설</th>
                            <td class="text-left">{{ info.f_fasility }}</td>
                        </tr>
                        <tr>
                            <th class="field">배정일</th>
                            <td class="text-left">{{ (info.f_assign_date != '0000-00-00 00:00:00') ? $moment(info.f_assign_date).format('YYYY-MM-DD') : '' }}</td>
                        </tr>
                        <tr>
                            <th class="field">담당자</th>
                            <td class="text-left">{{ info.f_manager }}</td>
                        </tr>
                        <tr>
                            <th class="field">이송완료</th>
                            <td class="text-left">{{ info.f_move_result }}</td>
                        </tr>
                        <tr>
                            <th class="field">이송일</th>
                            <td class="text-left">{{ (info.f_move_date != '0000-00-00 00:00:00') ? $moment(info.f_move_date).format('YYYY-MM-DD') : '' }}</td>
                        </tr>
                        <tr>
                            <th class="field">중증도 레벨</th>
                            <td class="text-left" colspan="3">{{ info.f_symptom_level }}</td>
                        </tr>
                        <tr>
                            <th class="field">중증도 상태</th>
                            <td class="text-left" colspan="3">{{ info.f_symptom_type }}</td>
                        </tr>
                        <tr>
                            <th class="field" colspan="4">변경 이력</th>
                        </tr>
                        <tr>
                            <td class="text-left" colspan="4">
                                <ul v-if="dataList.length>0">
                                    <li v-for="(data, index) in dataList" :key="index">
                                        {{data}}
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-profile" v-else>
                        <colgroup>
                            <col width="18%">
                            <col width="32%">
                            <col width="18%">
                            <col width="32%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">연번</th>
                            <td class="text-left">{{ info.fidx }}</td>
                            <th class="field">접수연번</th>
                            <td class="text-left">{{ info.fnum }}</td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">{{ info.fpatientName }} ({{ info.fpatientAge }}세)</td>
                            <th class="field">생년월일</th>
                            <td class="text-left">{{ (info.fpatientBirth != '0000-00-00 00:00:00') ? $moment(info.fpatientBirth).format('YYYY-MM-DD') : '' }}</td>
                        </tr>
                        <tr>
                            <th class="field">성별</th>
                            <td class="text-left">{{ info.fpatientSex }}</td>
                            <th class="field">지역</th>
                            <td class="text-left">{{ info.fregion }}</td>
                        </tr>
                        <tr>
                            <th class="field">배정예정</th>
                            <td class="text-left">{{ info.fassignTypeCode }}</td>
                            <th class="field">시설</th>
                            <td class="text-left">{{ info.ffasilityCode }}</td>
                        </tr>
                        <tr>
                            <th class="field">배정일</th>
                            <td class="text-left">{{ (info.fassignDate != '0000-00-00 00:00:00') ? $moment(info.fassignDate).format('YYYY-MM-DD') : '' }}</td>
                            <th class="field">담당자</th>
                            <td class="text-left">{{ info.fmanagerCode }}</td>
                        </tr>
                        <tr>
                            <th class="field">이송완료</th>
                            <td class="text-left">{{ info.ftransferState }}</td>
                            <th class="field">이송일</th>
                            <td class="text-left">{{ (info.fmoveDate != '0000-00-00 00:00:00') && (info.fmoveDate != null) ? $moment(info.fmoveDate).format('YYYY-MM-DD') : '' }}</td>
                        </tr>
                        <tr>
                            <th class="field">중증도 레벨</th>
                            <td class="text-left" colspan="3">{{ info.fsymptomLevelCode }}</td>
                        </tr>
                        <tr>
                            <th class="field">중증도 상태</th>
                            <td class="text-left" colspan="3">{{ info.fsymptomTypeCode }}</td>
                        </tr>
                        <tr>
                            <th class="field">변경 이력</th>
                            <td class="text-left" colspan="3">
                                <ul v-if="dataList.length>0">
                                    <li v-for="(data, index) in dataList" :key="index">
                                        {{data.fregDate}} ({{data.fadminName}}) : {{data.fhistoryValue}}
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
export default {
    name: 'PopupPatient',
    data() {
        return {
            isVisible:false,
            info:{},
            dataList:[]
        }
    },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
    },
    // $el 생성후
    mounted() {

    },
    methods: {
        init(isVisible, obj){
            this.isVisible=isVisible;
            this.info = obj
            this.searchInfoFunc(obj.f_idx);
        },
        searchInfoFunc(f_idx){
            // let formData = new FormData();
            // formData.append('api_name', 'patientHistoryList');                    // API 이름
            // formData.append('f_idx', f_idx);	        // 접수일시작
            // formData.append('f_admin_id', this.userDataAdminId);
            let params = `{"fIdx":${f_idx}}`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocConfirmCase/detailName?params=' + queryString, null, 'searchInfoFuncResult');
        },
        searchInfoFuncResult(response) {
            let result = response;
            this.info = result.list;
            this.dataList = result.history;
            console.log(this.info);
            console.log(this.dataList);
        },
    }
}
</script>
<style scoped>
.prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 800px;
    background: #fff;
    padding: 0 20px 20px;}
.dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}

li { list-style-type: disc; list-style-position: inside;}
</style>
