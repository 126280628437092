<template>
    <div class="ConfirmedListManagerSearch">
        <main-header menuActive="search" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>확진자검색</h2>
                </div>

                <div class="row">
                    <div class="form_box">

                        <div class="row pdd-top-10">
                            <div class="searchBox">
                                <!-- 검색키워드 -->
                                <div class="SearchConditionCombo inline-block">
                                  <label class="searchTitle">키워드</label>
                                  <select class="select_ansimi width150" v-model="searchConditionCombo">
                                    <option v-for="(data, index) in searchConditionConfirmedList" :key="index" :value="data.code">{{data.name}}</option>
                                  </select>
                                  <input type="text" class="input type2 width300 mrg-left-10" v-model="sch_keyword" v-on:keyup.enter="searchFunc" />
                                </div>
                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchFunc">조회</a>
                            <a class="btn8" @click="reset">초기화</a>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <colgroup>
                                <!--								<col width="100" />-->
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="180" />
                                <col width="180" />
                                <col width="180" />
                                <col />
                            </colgroup>
                            <thead>
                            <tr>
                                <!--								<th>-->
                                <!--									<input type="checkbox" />-->
                                <!--								</th>-->
                                <th>NO</th>
                                <th>접수일</th>
                                <th>확진일</th>
                                <th>지역</th>
                                <th>이름</th>
                                <th>성별</th>
                                <th>나이</th>
                                <th>생년월일</th>

                                <th>중증도</th>
                                <!--<th>중증상태</th>-->
                                <th>기저질환</th>
                                <th>배정일</th>
                                <th>입소일</th>
                                <th>퇴소일</th>
                                <th>최종상태</th>
                                <th>시설</th>
                            </tr>
                            </thead>
                            <tbody v-if="dataList.length>0">
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td>{{parseInt(totalCount) - ((parseInt(page)-1)*20 + (parseInt(index)) ) }}</td>
                                
                                <td>{{ (data.f_reg_date != '0000-00-00 00:00:00') ? $moment(data.f_reg_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_confirm_date != '0000-00-00 00:00:00') ? $moment(data.f_confirm_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_region }}</td>
                                <td><a @click="popupPatientFunc(data)" class="text-link">{{ data.f_patient_name }}</a></td>
                                <td>{{ data.f_patient_sex }}</td>
                                <td>{{ data.f_patient_age }}</td>
                                <td>{{ data.f_patient_birth }}</td>

                                <td>{{ data.f_symptom_level }}</td>
                                <!--<td>{{ data.f_symptom_type }}</td>-->
                                <td>{{ data.f_disease_info }}</td>
                                <td>{{ (data.f_assign_date != '0000-00-00 00:00:00') ? $moment(data.f_assign_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_transfer_date != '0000-00-00 00:00:00') ? $moment(data.f_transfer_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_result_date != '0000-00-00 00:00:00') ? $moment(data.f_result_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_result }}</td>
                                <td>{{ data.f_fasility }}</td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="15">검색결과가 존재 하지 않습니다.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <paginate v-model="page" :page-count="totalPage" :prev-text="'Prev'" :next-text="'Next'" :containerClass="'pagination'" :click-handler="changePage"></paginate>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />

        <!-- 병상현황 팝업 -->
        <popup-condition ref="popupCondition" />

        <!-- 증상변경, 상태변경, 팝업 -->
        <popup-mobile-combo ref="popupMobileCombo" />

        <!-- 기절질환 팝업 -->
        <popup-underlying-disease ref="popupUnderlyingDisease" />

        <!-- 전원등록 팝업 -->
        <popup-confirmed-transferred ref="popupConfirmedTransferred" />

        <!-- 전원등록 팝업 -->
        <popup-severity ref="popupSeverity" />

        <!-- 퇴원/퇴실 팝업 -->
        <popup-confirmed-exit ref="popupConfirmedExit" />



        <!-- 환자정보 팝업 -->
        <popup-patient ref="popupPatient" />

    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';

import searchTab from '@/components/SearchTab';
//import searchConditionBox from '@/components/SearchConditionBox';
import Paginate from 'vuejs-paginate'

import popupCondition from '@/components/PopupCondition';
import popupMobileCombo from '@/components/PopupMobileCombo';
import popupUnderlyingDisease from '@/components/PopupUnderlyingDisease';
import popupConfirmedTransferred from '@/components/PopupConfirmedTransferred.vue';
import popupSeverity from '@/components/PopupSeverity.vue';
import popupConfirmedExit from '@/components/PopupConfirmedExit.vue';
import PopupPatient from "@/components/PopupPatient";

export default {
    name: 'ConfirmedListManager',
    data() {
        return {
            sch_reg_start_date:'',   // 접수일시작
            sch_reg_end_date:'',   // 접수일끝
            sch_confirm_start_date:'',   // 확진일시작
            sch_confirm_end_date:'',   // 확진일끝
            sch_assign_start_date:'',   // 배정일시작
            sch_assign_end_date:'',   // 배정일끝

            sort_key : '',        // Tab 정보
            sort_data : [
                { code:'', name:'전체' },
                { code:'G0302', name:'입실대기' },
                { code:'G0303', name:'입소중' },
                { code:'G0308', name:'완치 (퇴소/퇴원)' },
                { code:'G0304', name:'전원' },
            ],

            searchConditionCombo : 'name',
            sch_keyword:'',

            list_fasility:[],
            list_fasility_popup : [],           // 시설별 리스트
            list_move_reason : [],             // 배정상태 리스트

            list_symptom_state:[],

            list_result_popup : [],           // 시설별 리스트


            page:1,
            totalPage:0,
            totalCount:0,
            dataList:[],
            statusData:{
            },
        }
    },
    components: { PopupPatient,mainHeader, mainFooter, Paginate, popupCondition, popupMobileCombo, popupUnderlyingDisease, popupConfirmedTransferred, popupSeverity, popupConfirmedExit },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
        sort_key(){ this.searchFunc() }
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {

    },
    methods: {
        init(){
            //let formData = new FormData();
            //formData.append('api_name', 'patientCreateCode');
            //this.ajaxPost('', formData,'initResult');

            this.ajaxGet('nocCommon/code?params=', null, 'initResult');
        },
        initResult(response){
            //let result = response.result_data;
            let result = response;

            // 미배정사유별
            //this.list_symptom_state = result.list_symptom_state;
            this.list_symptom_state = result.symptomStateList;

            // 시설별, 시설별 팝업
            //let vm = this;
            //result.list_fasility.forEach(function(obj){
            //    vm.list_fasility.push(obj)
            //    vm.list_fasility_popup.push(obj)
            //});
            //this.list_fasility.unshift({ code:'', name:'선택' });
            //this.list_fasility_popup.unshift({ code:'', name:'선택' });

            let vm = this;
            result.fasilityList.forEach(function(obj){
                vm.list_fasility_org.push(obj)
                vm.list_fasility.push(obj)
                vm.list_fasility_popup.push(obj)
            });

            // this.list_fasility.unshift({ code:'', name:'전체' });
            this.list_fasility_popup.unshift({ code:'', name:'선택' });


            // 전원사유
            //this.list_move_reason = result.list_move_reason;
            this.list_move_reason = result.moveReasonList;
            this.list_move_reason.unshift({ code:'', name:'선택' });

            // 최종결과
            //this.list_result = result.list_result;
            this.list_result = result.resultList;
            this.list_result_popup.unshift({ code:'', name:'선택' });

            this.searchFunc();
        },
        // 초기화
        reset(){
            this.sch_reg_start_date = '';
            this.sch_reg_end_date = '';
            this.sch_confirm_start_date = '';
            this.sch_confirm_end_date = '';
            this.sch_assign_start_date = '';
            this.sch_assign_end_date = '';

            this.sch_keyword = '';
            this.sort_key = '';
            this.page = 1;

            this.searchFunc();
        },
        searchFunc(){
            this.searchListFunc(1)
        },
        // 리스트 검색
        searchListFunc(pageIndex){

             if (this.sch_reg_start_date && !this.sch_reg_end_date){
               this.showAlert('', '접수 시작일자를 선택해 주세요.');
               return false;
             }

             if (this.sch_reg_start_date && !this.sch_reg_end_date){
               this.showAlert('', '접수 종료일자를 선택해 주세요.');
               return false;
             }

             let sch_reg_start_date = (this.sch_reg_start_date) ? this.$moment(this.sch_reg_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
             let sch_reg_end_date = (this.sch_reg_end_date) ? this.$moment(this.sch_reg_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

             if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
               this.showAlert('', '확진 시작일자를 선택해 주세요.');
               return false;
             }

             if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
               this.showAlert('', '확진 종료일자를 선택해 주세요.');
               return false;
             }

             let sch_confirm_start_date = (this.sch_confirm_start_date) ? this.$moment(this.sch_confirm_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
             let sch_confirm_end_date = (this.sch_confirm_end_date) ? this.$moment(this.sch_confirm_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

             if (this.sch_assign_start_date && !this.sch_assign_end_date){
               this.showAlert('', '확진 시작일자를 선택해 주세요.');
               return false;
             }

             if (this.sch_assign_start_date && !this.sch_assign_end_date){
               this.showAlert('', '확진 종료일자를 선택해 주세요.');
               return false;
             }

             let sch_assign_start_date = (this.sch_assign_start_date) ? this.$moment(this.sch_assign_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
             let sch_assign_end_date = (this.sch_assign_end_date) ? this.$moment(this.sch_assign_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            /*
            let formData = new FormData();
            formData.append('api_name', 'patientMobileSearchList');         // API 이름
            formData.append('sch_reg_start_date', sch_reg_start_date);	        // 접수일시작
            formData.append('sch_reg_end_date', sch_reg_end_date);	            // 접수일끝
            formData.append('sch_confirm_start_date', sch_confirm_start_date);	// 확진일시작
            formData.append('sch_confirm_end_date', sch_confirm_end_date);	    // 확진일끝
            formData.append('sch_assign_start_date', sch_assign_start_date);	    // 배정일시작
            formData.append('sch_assign_end_date', sch_assign_end_date);	        // 배정일끝
            formData.append('sch_keyword', this.sch_keyword);	        // 키워드
            formData.append('sort_key', this.sort_key);	                        // Tab
            formData.append('page_num', pageIndex);	                // 현재 페이지 번호
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'searchListFuncResult');
            */

            let params = `{
                            "pagination": {
                              "curPage": "${pageIndex}",
                              "pageScale": 20
                            },
                            "search": {
                              "keyword": "${this.searchConditionCombo}",
                              "keyword_txt": "${this.sch_keyword}"
                            }
                          }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocAssign/list?params=' + queryString, null, 'searchListFuncResult');
       
        },
        searchListFuncResult(response) {
            /*
            let result = response.result_data;
            this.dataList = result.list_data;
            this.statusData = result.status_data;
            if (result.total_cnt == 0){
                this.totalPage = 0;
                this.totalCount=0;
            }else{
                this.totalPage = parseInt(result.total_page);
                this.totalCount = parseInt(result.total_cnt);
            }
            */

            /*
            <td>{{ data.f_symptom_level }}</td>
                                <!--<td>{{ data.f_symptom_type }}</td>-->
                                <td>{{ data.f_disease_info }}</td>
                                <td>{{ (data.f_assign_date != '0000-00-00 00:00:00') ? $moment(data.f_assign_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_transfer_date != '0000-00-00 00:00:00') ? $moment(data.f_transfer_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_result_date != '0000-00-00 00:00:00') ? $moment(data.f_result_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_result }}</td>
                                <td>{{ data.f_fasility }}</td>
            */

            let result = response;
            this.dataList = [];
            let tmpMap;
            result.list.map(el => {
                tmpMap = {};
                tmpMap.f_idx = el.seq;
                tmpMap.f_date_num = el.num;
                tmpMap.f_reg_date = el.registDt;
                tmpMap.f_confirm_date = el.cfmDt;
                tmpMap.f_region = el.region;
                tmpMap.f_patient_name = el.name;
                tmpMap.f_patient_sex = el.sex;
                tmpMap.f_patient_age = el.age;
                tmpMap.f_patient_birth = el.birth;
                tmpMap.f_symptom_level = el.symptomLevel;
                tmpMap.f_disease_info = el.symptomType;
                tmpMap.f_assign_date = el.assignDate;
                tmpMap.f_transfer_date = el.transferDate;
                tmpMap.f_result_date = el.resultDate;
                tmpMap.f_result = el.fanalResult;
                tmpMap.f_fasility = el.fasility;

                this.dataList.push(tmpMap);
            });
            
            if (result.count == 0){
                this.totalPage = 0;
                this.totalCount = 0;
            }else{
                this.totalPage = parseInt(result.pagination.maxPage);
                this.totalCount = parseInt(result.count);
            }
        },
        // 페이지 변경
        changePage(){
            this.searchListFunc(this.page)
        },

        // 퇴실처리
        confirmedExitFunc(obj){
            this.$refs.popupConfirmedExit.init(true, obj,this.list_result , this.page)
        },
        // 삭제 처리
        commonConfirm(formData){
            this.ajaxPost('', formData,'commonConfirmResult');
        },
        // 삭제 처리 결과
        commonConfirmResult(response) {
            this.showAlert('', response.result_msg, "searchListFunc", 1);
        },
        // 병상현황 팝업
        popupConditionFunc(){
            this.$refs.popupCondition.init(true, this.statusData, this.page)
        },
        // 병상현황 팝업
        popupMobileFunc(obj, list, mode){
            this.$refs.popupMobileCombo.init(true, obj, list, mode, this.page)
        },
        // 기절질환 등록 팝업
        popupUnderlyingDiseaseFunc(obj){
            this.$refs.popupUnderlyingDisease.init(true, obj, this.page)
        },
        // 전원등록
        popupConfirmedTransferredFunc(obj){
            this.$refs.popupConfirmedTransferred.init(true, obj, this.list_fasility_popup, this.list_move_reason, this.page)
        },
        // 중증도 변경
        popupSeverityFunc(obj){
            this.$refs.popupSeverity.init(true, obj, this.list_symptom_state, this.page)
        },
        excelDownFunc(){
            let url=this.rootUrl+'/_excel/excel_patient_region.php?f_admin_id='+this.userDataAdminId;
            this.excelExport(url);
        },
        // 환자정보 변경
        popupPatientFunc(obj){
          this.$refs.popupPatient.init(true, obj)
        }
    }
}
</script>

<style scoped>
</style>
