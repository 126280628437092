<template>
    <div class="PopupMobileCombo" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3>
                          <template v-if="mode == 'G0908'">
                            # 증상변경
                          </template>
                          <template v-if="mode == 'G0910'">
                            # 상태변경
                          </template>
                          <template v-if="mode == 'G0905'">
                            # 전원등록
                          </template>
                          <span style="color: darkred; font-size: 12px;">
                            {{
                              data.f_patient_name + '('+ data.f_patient_age +')' + ' / ' + ((data.f_patient_birth != '0000-00-00 00:00:00') ? $moment(data.f_patient_birth).format('YYYY-MM-DD') : '') + '('+ data.f_patient_sex +')'
                            }}
                          </span>
                        </h3>
                    </div>
                    <table class="table table-profile" v-if="mode == 'G0905'">
                        <colgroup>
                            <col width="80">
                          <col />
                        </colgroup>
                        <tbody>
                        <tr>
                          <th>
                            전원 사유
                          </th>
                          <td class="text-center">
                            <select class="select_ansimi width-100"
                                    v-model="f_chang_value"
                            >
                              <option v-for="(data, index) in list" :key="index" :value="data.code">
                                {{data.name}}
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            시설명
                          </th>
                          <td class="text-center">
                            <select class="select_ansimi width-100"
                                    v-model="f_chang_value"
                            >
                              <option v-for="(data, index) in list" :key="index" :value="data.code">
                                {{data.name}}
                              </option>
                            </select>
                          </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    export default {
        name: 'PopupMobileCombo',
        data() {
            return {
                isVisible:false,
                data:[],
                list : [],
                f_chang_value : '',
                mode:'',
                page : '',
                underlyingDiseaseVal : [],            // 기저질환
                underlyingDiseaseData : [             // 기저질환 리스트
                    { code:'1', name:'당뇨' },
                    { code:'2', name:'투석환자' },
                    { code:'3', name:'만성폐질환' },
                    { code:'4', name:'심부전등 심혈관질환' },
                    { code:'5', name:'항암치료 장기이식' },
                    { code:'6', name:'와상 환자' },
                    { code:'7', name:'정신질환자' },
                    { code:'8', name:'임신부' }
                ],
            }
        },
        components: {  },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, list, mode, page){
                this.isVisible=isVisible;
                this.data = obj;
                this.list = list;
                this.mode = mode;
                this.page = page;
            },
            sendFunc(){
                if (!this.f_chang_value){
                    this.showAlert('', '상태를 선택해 주세요.');
                    return false;
                }

                let formData = new FormData();
                formData.append('api_name', 'patientStateChange');  // API 이름
                formData.append('f_idx', this.data.f_idx);	                    // 확진자 기본키
                formData.append('f_chang_code', this.mode);	        // 상태변경 분류값
                formData.append('f_chang_value', this.f_chang_value);
                formData.append('f_admin_id', this.userDataAdminId);

                this.ajaxPost('', formData,'sendFuncResult');
            },
            sendFuncResult(response) {
                this.showAlert('', response.result_msg,"close");
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
