<template>
    <div class="PopupCondition" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># {{ f_fasility_name }} <span style="color: darkred; font-size: 12px;">병상현황</span> </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="25%">
                            <col width="25%">
                            <col width="25%">
                            <col width="25%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">총병상</th>
                            <th class="field">사용중</th>
                            <th class="field">입실불가능</th>
                            <th class="field">입실가능</th>
                        </tr>
                        <tr>
                            <td class="text-center">
                                <input type="text" class='input type2 width-100 text-center' v-model="f_total_room"  onKeyup="this.value=this.value.replace(/[^0-9]/g,'');" />
                            </td>
                            <td class="text-center">
                              {{ f_use_room }}
                            </td>
                            <td class="text-center">
                                <input type="text" class='input type2 width-100 text-center' v-model="f_imposible_room"  onKeyup="this.value=this.value.replace(/[^0-9]/g,'');" />
                            </td>
                            <td class="text-center">
                              {{ f_unused_room }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    export default {
        name: 'PopupCondition',
        data() {
            return {
                isVisible:false,
                f_idx : '',
                f_fasility_name : '',
                f_total_room : 0,
                f_use_room : 0,
                f_unused_room : 0,
                f_imposible_room : 0,
                page:'',                            // 페이지
            }
        },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, page){
                this.isVisible=isVisible;
                this.f_idx = obj.fidx; // 시설코드
                this.f_fasility_name = obj.ffasilityName;
                this.f_total_room = obj.ftotalRoom;
                this.f_use_room = obj.fuseRoom;
                this.f_unused_room = obj.fposibleRoom;
                this.f_imposible_room = obj.fimposibleRoom;
                this.page = page
            },
            sendFunc(){
                if (!this.f_total_room){
                    this.showAlert('', '총병상을 입력해 주세요.');
                    return false;
                }

                if (!this.f_use_room){
                    this.showAlert('', '사용중병상을 입력해 주세요.');
                    return false;
                }

                if (!this.f_imposible_room){
                    this.showAlert('', '사용불가능병상을 입력해 주세요.');
                    return false;
                }

                if (!this.f_unused_room){
                    this.showAlert('', '사용가능병상을 입력해 주세요.');
                    return false;
                }

                /*

                let formData = new FormData();
                formData.append('api_name', 'fasilityRoomChange');
                formData.append('f_idx', this.f_idx);
                formData.append('f_total_room', this.f_total_room);
                formData.append('f_use_room', this.f_use_room);
                formData.append('f_imposible_room', this.f_imposible_room);
                formData.append('f_unused_room', this.f_unused_room);
                formData.append('f_admin_id', this.userDataAdminId);

                this.ajaxPost('', formData,'sendFuncResult');
                */
               
                let data = {
                    "fIdx": this.f_idx,
                    "fTotalRoom": this.f_total_room,
                    "fImposibleRoom": this.f_imposible_room,
                    "adminId": this.userDataAdminId,
                }

                this.ajaxPost('nocFacility/updates', data,'sendFuncResult');
            },
            sendFuncResult(response) {
                this.showAlert('', '저장 되었습니다.',"close");
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
