<template>
    <div class="InsulatorTab">
        <ul v-if="searchTabData">
            <li v-for="(data, index) in searchTabData" :key="index" :class="{'on':(data.code==searchTabVal)}">
                <a @click="$emit('update:searchTabVal', data.code)">
                    <span>{{data.name}}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'SearchTab',
        data() {
            return {
            }
        },
        props: ['searchTabData', 'searchTabVal'],
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {
        },
        methods: {
        }
    }
</script>
<style scoped>
.link_tab li a {
    width: auto;
    min-width: 80px;
    padding: 0 10px;
}
</style>
